.gbackground {
  background: rgb(178,61,177);
  background: -moz-linear-gradient(-40deg, rgba(178,61,177,1) 0%, rgba(130,44,129,1) 100%);
  background: -webkit-linear-gradient(-40deg, rgba(178,61,177,1) 0%,rgba(130,44,129,1) 100%);
  background: linear-gradient(140deg, rgba(178,61,177,1) 0%,rgba(130,44,129,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b23db1', endColorstr='#822c81',GradientType=1 );
}

.btn {
  @include transition(all 0.3s ease-in-out);
  font-family: $headings-font-family;
  overflow: hidden;
  position: relative;
  span {
    position: relative;
    z-index: 2;
  }
  &::after {
    position: absolute;
    content: '';
    left: -20%;
    width: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background: transparent;
    @include transform(skewX(-45deg));
    @include transition(all 0.3s ease);
  }
  @include hover-focus {
    &::after {
      width: 140%;
    }
  }
  &.btn-white {
    background: transparent;
    color: $gray-darkest;
    border: 2px solid $white;
    &::after {
      background: $white;
      width: 140%;
    }
    @include hover-focus {
      color: $white;
      &::after {
        width: 0;
      }
    }
  }
  &.btn-shadow {
    box-shadow: 0 0 25px 0 rgba(0,0,0,0.15);
  }
  &.btn-black {
    background: $gray-darkest;
    color: $white;
    text-transform: uppercase;
    font-family: $headings-font-family;
  }
  &.btn-trans {
    background: transparent;
    color: $white;
    border: 2px solid $white;
    &::after {
      background: $white;
    }
    @include hover-focus {
      color: $gray-darkest;
    }
  }
  &.btn-purple {
    color: $white;
    @extend .gbackground;
    &.btn-trans {
      background: transparent;
      border: 2px solid $brand-primary;
      color: $brand-primary;
      @include hover-focus {
        color: $white;
      }
    }
    &::after {
      background: rgb(178,61,177);
    }
  }
}
