html,
body {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  width: 100%;
  font-size: #{$font-base-size}px;
  position: relative;
  @media only screen and (min-width : 768px) {
    background-image: url('../images/body_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
  &::before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: -10;
    background: url('../images/body_bg.jpg') repeat center;
    background-size: cover;
  }
  &.open {
      overflow: hidden;
      position: fixed;
  }
}
main {
  margin-top: rem-calc(90);
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  letter-spacing: -1px;
}
.h4, h4 {
  font-size: rem-calc(24);
}
.c-bp {
  color: $brand-primary !important;
}
a {
  outline: 0;
  @include transition(all 0.3s ease);
  @include hover-focus {
    text-decoration: none;
  }
}
img {
  max-width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
iframe {
  max-width: 100% !important;
}
em {
  font-style: normal;
}
.npmd {
  @media only screen and (max-width : 768px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.shadow {
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    z-index: 0;
  }
}
.transition {
  @include transition(all 0.3s ease);
}
.wblock {
  background: $white;
}
.gblock {
  @extend .gbackground;
  h1,h2,h3,h4,p {
    color: $white;
  }
}
.bblock {
  background: $gray-darkest;
  h1,h2,h3,h4,p, div {
    color: $white;
  }
}
small {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $brand-primary;
  letter-spacing: 1.5px;
}
h1 {
  font-size: rem-calc(35);
}
h2 {
  font-size: rem-calc(25);
  .fade {
    opacity: 0.4;
  }
}
@media only screen and (min-width : 320px) {
}
@media only screen and (min-width : 480px) {
}
@media only screen and (min-width : 768px) {
  h1 {
    font-size: rem-calc(40);
  }
  h2 {
    font-size: rem-calc(30);
  }
}
@media only screen and (min-width : 1025px) {
}
@media only screen and (min-width : 1200px) {
}
