.contact {
  .acf-map {
    width: 100%;
    min-height: rem-calc(460);
  }
  .col-lg-5.form {
    margin-top: rem-calc(-130);
  }
  .wblock {
    h3,h4 {
      display: none;
    }
    .widget {
      overflow: hidden;
    }
    em {
      color: $brand-primary;
      font-weight: bold;
    }
    a {
      display: block;
      color: $gray;
    }
    .btn {
      margin: rem-calc(40 0);
    }
    .btn-social {
      background: #f4e2f3; //gay fugly pink
      color: $brand-primary;
      @include hover-focus {
        background: $brand-primary;
        color: $white;
      }
    }
  }
  .contactform {
    margin-top: rem-calc(40);
    @media only screen and (min-width : 768px) {
      margin-top: rem-calc(20);
    }
    h1,h2,h3,p {
      margin-bottom: rem-calc(20);
    }
  }
  form {
    margin-bottom: rem-calc(80);
  }
}
