.arrownav {
  width: rem-calc(50);
  height: rem-calc(50);
  background: none;
  border: none;
  box-shadow: none;
  border-radius: 0;
  opacity: 1;
  top: 110%;
  @media only screen and (min-width : 768px) {
    top: 50%;
  }
  &::before, &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &::before {
    border: 2px solid $gray-darkest;
    @extend .transition;
  }
  &::after {
    background: url("../images/icon_arrow.svg") no-repeat center;
    background-size: rem-calc(22);
  }
  @include hover-focus {
    background: none;
    box-shadow: none;
    &::before {
      @include transform(rotate(90deg));
    }
  }
  svg {
    display: none;
  }
  &.previous {
    left: 0;
    &::after {
      @include transform(rotate(180deg));
    }
  }
  &.next {
    right: 0;
  }
}

.testimonials .flickity-prev-next-button {
  @extend .arrownav;
}
.featured-slideshow, .gallery, .months-slideshow {
  .flickity-prev-next-button {
    width: rem-calc(70);
    height: rem-calc(70);
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    z-index: 6;
    opacity: 0.5;
    @include hover-focus {
      opacity: 1;
    }
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('../images/icon_chevron.svg') no-repeat center;
      background-size: contain;
    }
    svg {
      display: none;
    }
    &.previous {
      &::before {
        @include transform(rotate(180deg));
      }
    }
  }
}
.months-slideshow {
  .flickity-prev-next-button {
    width: rem-calc(40);
    height: rem-calc(40);
  }
}
.flickity-page-dots {
  bottom: rem-calc(-60);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: auto;
  .dot {
    background: $gray-darkest;
    border-radius: 0;
    margin: 0 5px;
  }
}
