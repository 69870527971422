.newsletter {
  &.front {
    margin-top: rem-calc(-155);
    &.noevents {
      margin-top: 0;
    }
  }
  &.archive {
    @include transform(translate3d(0, rem-calc(30), 0));
    margin-bottom: rem-calc(-100);
    position: relative;
    z-index: 2;
    @media only screen and (min-width : 768px) {
      @include transform(translate3d(0, rem-calc(100), 0));
      margin-bottom: 0;
    }
  }
  p {
    margin: rem-calc(30 0 50);
    color: $gray-darkest;
  }
  .btn {
    padding: rem-calc(0 70);
    cursor: pointer;
  }
  @include e('wrapper') {
    padding: rem-calc(40 20);
    background: url('../images/newsletter_bg.jpg') no-repeat center;
    background-size: cover;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.3);
    position: relative;
    @media only screen and (min-width : 768px) {
      padding: rem-calc(60);
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.3;
      background: url('../images/blus-logo-donker.svg') no-repeat 10% 50%;
      background-size: contain;
      background-position: center;
      @media only screen and (min-width : 768px) {
        background: url('../images/blus-logo-donker.svg') no-repeat center;
        background-size: contain;
      }
    }
  }
  @media only screen and (max-width : 768px) {
    .input-group {
      display: block;
      input.form-control {
        line-height: rem-calc(40);
        width: 100%;
        margin-bottom: rem-calc(10);
      }
    }
  }
  .form-control {
    @include hover-focus {
      box-shadow: 0 0 15px 0 rgba(178,61,177,0.3);
    }
  }
}
