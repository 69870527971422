header {
  width: 100%;
  background: url('../images/header_bg.jpg') no-repeat center;
  background-size: cover;
  box-shadow: 0 0 50px rgba(0,0,0,0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  nav {
    position: relative;
    width: 100%;
    border: none;
    margin: 0;
    img {
      height: rem-calc(70);
      margin: rem-calc(10 0);
      @media only screen and (min-width : 1025px) {
        height: rem-calc(70);
      }
    }
    .nav-toggle {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      right: 15px;
      top: 0;
      width: rem-calc(50);
      height: 100%;
      z-index: 10;
      &.open {
        span {
          @include transition(all 0.3s ease);
          background-color: transparent;
          &:before, &:after {
            @include transition(all 0.3s ease);
            top: 0;
            background-color: $white;
          }
          &:before {
            @include transform(rotate(-45deg));
          }

          &:after {
            @include transform(rotate(45deg));
          }
        }
      }

      span {
        @include transition(all 0.3s ease);
        background-color: $gray-light;
        display: block;
        height: 3px;
        right: 0;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: rem-calc(25);
        &:before,
        &:after {
          @include transition(all 0.3s ease);
          background-color: $gray-light;
          content: '';
          height: 3px;
          right: 0;
          position: absolute;
          width: 100%;
        }
        &:before {
          top: -8px;
        }
        &:after {
          top: 8px;
        }
      }
    }
    .btn {
      margin: rem-calc(15 0 15 15);
    }
    .phone {
      width: rem-calc(50);
      height: rem-calc(50);
      margin: rem-calc(20 0);
      line-height: rem-calc(50);
      text-align: center;
      border-radius: 50%;
      color: $white;
      padding: 0;
      @include hover-focus {
        &::after {
          width: 0;
        }
      }
    }
    .nav-wrapper {
      @include transition(all 0.3s ease);
      @extend .gbackground;
      height: 100%;
      right: -100%;
      opacity: 1;
      position: fixed;
      bottom: 0;
      visibility: hidden;
      width: 80%;
      z-index: 8;
      &.open {
        @include transition(all 0.3s ease);
        opacity: 1;
        right: 0;
        visibility: visible;
        overflow-y: scroll;
        .btn {
          margin: rem-calc(30);
        }
      }
      ul.nav {
        margin: 0;
        padding: rem-calc(80 30 50 30);
        position: relative;
        width: 100%;
        li {
          display: inline;
          float: left;
          list-style-type: none;
          width: 100%;
          border-bottom: 1px solid rgba(255,255,255,0.4);
          //default li styles
          &:last-of-type {
            padding-right: 0;
          }
          &:first-of-type {
            padding-left: 0;
          }

          //link styles
          a {
            display: inline-block;
            line-height: initial;
            color: $white;
            text-transform: uppercase;
            font-family: $headings-font-family;
            padding: rem-calc(15 0);
            &.active, &:hover, &:focus {
              background: none;
              color: $gray-lightest;
            }
          }
          &.dropdown>a, ul li a { //different styles for dropdown links
            color: $white;
            &:hover, &:focus {
              color: $gray-lightest;
            }
          }
          &.dropdown.isActive>a, ul li a.active {
            color: $gray-lightest;
          }

          //dropdown styles
          &.dropdown {
            position: relative;
            a {
              display: inline;
              float: left;
              width: 85%;
            }
            .customcaret {
              display: inline-block;
              float: left;
              height: rem-calc(60);
              width: 15%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              i {
                @include transform(rotate(-90deg));
                @include transition(all 0.3s ease);
                color: $white;
                float: right;
                font-size: 30px;
                height: 30px;
                line-height: 30px;
              }

              &[aria-expanded="true"] {
                i {
                  @include transform(rotate(0deg));
                  @include transition(all 0.3s ease);
                }
              }
            }
            ul.dropdown-menu {
              background-color: transparent;
              border: none;
              height: 0!important;
              margin: 0;
              opacity: 0;
              padding: rem-calc(0 20);
              margin-top: rem-calc(-10);
              position: static;
              visibility: hidden;
              width: 100%;
              @include border-radius(0px);
              @include box-shadow(none);
              @include transition(all 0.3s ease);
              li.dropdown {
                ul.dropdown-menu {
                  height: 0;
                  padding: 0 rem-calc(20);
                  &.in {
                    height: auto;
                    padding: rem-calc(20 20 0);
                  }
                }
              }
              &.in, &.show {
                height: auto!important;
                opacity: 1;
                visibility: visible;
              }
              li:last-of-type {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width : 1025px) {
  header {
    nav {
      .nav-wrapper {
        background: none;
        opacity: 1;
        position: static;
        visibility: visible;
        width: 100%;
        ul.nav {
          display: block;
          float: right;
          overflow: inherit;
          width: auto;
          height: auto;
          padding: 0;
          position: static;
          li {
            display: inline-block;
            float: left;
            list-style-type: none;
            width: auto;
            line-height: rem-calc(90);
            border-bottom: none;
            //link styles
            &.dropdown>a,ul li a { //different styles for dropdown links
              color: $gray-light;
              &:hover, &:focus, &.active {
                color: $black;
              }
            }
            &.dropdown {
              &:hover, &:focus {
                > ul.dropdown-menu {
                  display: block!important;
                  opacity: 1!important;
                  visibility: visible!important;
                }
                a {
                  border-bottom: 0 !important;
                  &::after {
                    width: 0;
                  }
                }
              }
              a {
                width: auto;
              }
              >a {
                float: none;
              }
              .customcaret {
                display: none;
              }
              ul.dropdown-menu {
                @include box-shadow(0 0 30px rgba(0,0,0,0.1));
                background-color: $gray-darkest;
                display: none!important;
                height: auto!important;
                left: -50%;
                right: -50%;
                margin: 0 auto;
                min-width: rem-calc(150);
                padding: 0;
                position: absolute;
                top: 100%;
                @include calc(top, '100% - ' rem-calc(13));
                text-align: center;
                border: 1px solid $gray-darkest;
                width: 100%;
                z-index: 5;
                &::before {
                  position: absolute;
                  content: '';
                  width: 0;
                	height: 0;
                  top: rem-calc(-10);
                  @include calc(left, '50% - ' rem-calc(15));
                	border-left: rem-calc(15) solid transparent;
                	border-right: rem-calc(15) solid transparent;
                	border-bottom: rem-calc(10) solid $gray-darkest;
                }
                li {
                  height: auto;
                  width: auto;
                  display: block;
                  margin: rem-calc(0 10);
                  @include calc(min-width, '100% - ' rem-calc(20)); // 20 = 2x 10 margin
                  border-bottom: 1px solid $gray;
                  &:last-of-type {
                    border-bottom: 0;
                  }
                  &:hover, &:focus {
                    > ul.dropdown-menu {
                      display: block!important;
                      left: calc(100%);
                      opacity: 1!important;
                      position: absolute;
                      top: 0;
                      visibility: visible!important;
                    }
                  }
                  &.dropdown {
                    ul.dropdown-menu {
                      @include box-shadow(0 0 30px rgba(0,0,0,0.1));
                      height: auto!important;
                      padding: 0 !important;
                    }
                  }
                  a {
                    height: auto;
                    display: block;
                    width: 100%;
                    padding: rem-calc(15 5);
                    float: left;
                    margin: 0;
                    color: $gray;
                    &.active, &:hover, &:focus {
                      border-bottom: none;
                      color: $white;
                    }
                  }
                }
              }
            }
            &:last-of-type a {
              padding-right: 0;
            }
            &:first-of-type a {
              padding-left: 0;
            }
            a {
              padding: rem-calc(15 0);
              margin: rem-calc(0 18);
              color: $gray-light;
              position: relative;
              @include transition(border 0s, color 0.3s ease);
              &::after {
                position: absolute;
                content: '';
                bottom: 3px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 0;
                height: 3px;
                background: $brand-primary;
                @include transition(all 0.3s ease);
              }
              &.active, &:hover, &:focus {
                color: $gray-darkest;
                &::after {
                  width: rem-calc(30);
                }
              }
            }
          }
        }
        ul.more.vh {
          visibility: hidden;
        }
      }
    }
  }
}
