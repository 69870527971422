// Colors
$brand-primary:         #b23db1;
$brand-secondary:       #fff;

$white:  #fff;
$black:  #000;
$gray-darkest:              #2a2a2a;
$gray-dark:                 #757575;
$gray:                      #6d6d6d;
$gray-light:                #9e9e9e;
$gray-lighter:              #c6c6c6;
$gray-lightest:             #ededed;

$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;

$link-color:            $black;
$link-decoration:       none;
$link-hover-color:      none;
$link-hover-decoration: underline;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1500px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$grid-columns: 12;
$font-family-base: 'Hind', sans-serif;
$headings-font-family: 'Oswald', sans-serif;
$body-color: $gray;
$body-bg: $gray-darkest;
$font-base-size: 18;

$font-size-lg:   1.25rem;
$font-size-sm:   .9rem;
$font-size-xs:   .8rem;

$font-weight-xlight: 100;
$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;
$font-weight-xbold: 900;

$font-size-h1: 2.2rem;
$font-size-h2: 2rem;
$font-size-h3: 1.778rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.2rem;
$font-size-h6: 1rem;
//$headings-margin-bottom
$headings-font-weight: 					 $font-weight-light;

$font-weight-base:               $font-weight-normal;
$line-height-base:               1.5;
$headings-color:                 $gray-darkest;

$btn-border-radius-base:				 0;
$input-btn-border-width:         0px;
$btn-padding-x:                  1.55rem;
$btn-padding-y:                  0rem;
$btn-line-height:                3.2rem;
$btn-font-weight:                $font-weight-normal;

$input-padding-x:                .75rem;
$input-padding-y:                .5rem;
$input-color-placeholder: 			 #e0e0e0;

@mixin transform($transform) {
       -moz-transform: $transform;
  	    -ms-transform: $transform;
  	-webkit-transform: $transform;
            transform: $transform;
}

@mixin e($element) {
  &__#{$element} {
    @content;
  }
}
@mixin m($modifier) {
  &--#{$modifier} {
    @content;
  }
}

@function rem-calc($pixels) {
  @return #{$pixels/18}rem;
}

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@function rem-calc($target) {
    $max: length($target);
    $values: ();
    $context: $font-base-size;
    @for $i from 1 through $max {
        $x: 0;
        $x: (nth($target, $i) / $context) * 1rem;
        @if abs($x) == 0 {
            $values: append($values, 0);
        }
        @else {
            $values: append($values, $x);
        }
    }
    @return $values;
}
