form {
	margin: 0 auto;
	width: 100%;

	input[type=text],
	input[type=email],
	input[type=tel],
	input[type=number],
	input[type=password],
	textarea,
	select {
		background: $white;
		border: 2px solid $white;
		color: $black;
		display: block;
		height: rem-calc(55);
		line-height: rem-calc(55);
		margin-bottom: rem-calc(25);
		padding: rem-calc(0 15);
		text-align: left;
		font-family: $font-family-base;
		width: 100%;
    border-radius: 0;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
    @include transition(all 0.3s ease);
    @include hover-focus {
      border-color: $brand-primary;
      outline: none;
    }
	}

	textarea {
		min-height: rem-calc(160);
	}
  label.gfield_label {
    display: none;
  }
	input[type=submit], button[type=submit] {
		text-align: center;
    width: 100%;
		cursor: pointer;
    @extend .btn;
    @extend .btn-purple;
		@include transition(all 0.3s ease);
    &:active {
      @extend .gbackground;
    }
		@include hover-focus {
			outline: none;
		}
	}

	/* Gravity Form*/
	.gfield_checkbox li {
		position: relative;
		margin-bottom: rem-calc(10);
		cursor: pointer;
		&::before, &::after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
		}
		&::before {
			width: rem-calc(25);
			height: rem-calc(25);
			@extend .gbackground;
			content: '✓';
			font-size: rem-calc(18);
			text-align: center;
			font-weight: bold;
			line-height: rem-calc(30);
			color: $white;
		}
		&::after {
			content: 'Aanmelden nieuwsbrief';
			left: rem-calc(35);
		}
		&.active {
			&::before {
				content: '';
			}
		}
		input, label {
			visibility: hidden;
		}

	}
	.validation_error {
		padding: 15px;
		margin-bottom: rem-calc(25);
		border: 2px solid transparent;
		background-color: $gray-darkest;
		border-color: $white;
		color: $white;
	}

	.gfield_error {
		input[type=text],
		input[type=email],
		input[type=tel],
		input[type=number],
		input[type=password],
		textarea,
		select {
			border: 2px solid $brand-primary;
		}
	}

	.validation_message {
		color: #b94a48;
		font-size: 0.9em;
		padding: 5px;
	}

	.gfield_required {
		color: $brand-primary !important;
		padding: 0 0 0 5px;
	}

	.gfield_description {
		margin: -15px 0 15px;

	}

	ul {
		list-style: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	&.form-newsletter {
		label {
			&.gfield_label {
				display: none !important;
			}
		}

		.gform_footer {
			position: relative;
		}

		input[type=text],
		input[type=email],
		input[type=tel],
		input[type=number],
		input[type=password] {
			border: none;
			border-bottom: 1px solid $white;
			color: $white;
			width: 100%;

			&:focus {
				outline: none;
			}

		}

		.gform_wrapper .hidden_label input.large,
		.gform_wrapper .hidden_label select.large,
		.gform_wrapper .top_label input.large,
		.gform_wrapper .top_label select.large {
			width: 100% !important;
		}

		input[type=submit]{
			background: none;
			border: 1px solid $white;
			color: white;
			display: block;
			font-size: 18px;
			line-height: 28px;
			height: 30px !important;
			padding: 0;
			position: absolute; right: 15px; top: -55px;
			text-align: center;
			width: 30px !important;
      @extend .btn;
      @extend .btn-purple;
      &:active {
        background: inherit;
      }
			@include hover-focus {
        @extend .btn;
        @extend .btn-purple;
				background-color: $white;
				color: $brand-primary;
			}
		}
	}
}
