section.reserveren {
  padding-bottom: rem-calc(50);
  .gblock, .bblock {
    padding: rem-calc(20);
    @media only screen and (min-width : 768px) {
      padding: rem-calc(40);
    }
    margin-bottom: rem-calc(30);
  }
  .gblock {
    margin-top: rem-calc(30);
    @media only screen and (min-width : 768px) {
      margin-top: rem-calc(90);
    }
    p {
      margin: 0;
    }
  }
  .wblock {
    margin-top: rem-calc(90);
    @media only screen and (min-width : 768px) {
      margin-top: 0;
    }
    padding: rem-calc(30);
    @media only screen and (min-width : 768px) {
      padding: rem-calc(50);
    }
  }
  .bblock {
    label, p, li {
      display: block;
      color: $white;
    }
    h1 {
      margin-bottom: rem-calc(25);
    }
    @media only screen and (min-width : 768px) {
      .gf_left_half {
        float: left;
        width: 50%;
        padding-right: rem-calc(10);
      }
      .gf_right_half {
        float: left;
        width: 50%;
        padding-left: rem-calc(10);
      }
    }
  }

  .contactform {
    h1,h2,h3 {
      display: block;
      width: 100%;
    }
    h1,h2,h3,p {
      margin-bottom: rem-calc(20);
    }
    input[type=text],
    input[type=email],
  	input[type=tel],
  	input[type=number],
  	input[type=password],
  	textarea {
      border-color: $gray-lightest;
      box-shadow: none;
    }
  }
}
