.archive {
  .wblock {
    padding: rem-calc(40 0 20 0);
    margin-top: rem-calc(50);
    img {
      opacity: 0.2;
    }
    .btn {
      padding: 0 rem-calc(20);
    }
  }
}
.events {
  .months {
    background: $gray-darkest;
    overflow: hidden;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    &::before {
      position: absolute;
      content: attr(data-year);
      color: $white;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0.1;
      font-family: 'Oswald', sans-serif;
      font-size: rem-calc(147);
      line-height: rem-calc(100);
      @media only screen and (min-width : 768px) {
        right: inherit;
      }
    }
    li {
      display: inline-block;
      a {
        color: $gray-light;
        padding: 0 rem-calc(5);
        line-height: rem-calc(100);
        border-bottom: 4px solid transparent;
        margin: 0;
        display: block;
        position: relative;
        z-index: 5;
        @extend .h4;
        font-size: rem-calc(24);
        &.active, &:hover, &:focus {
          color: $white;
          border-color: $brand-primary;
        }
      }
    }
  }
  @include e('single') {
    display: none;
    &.active {
      display: block;
    }
    @include hover-focus {
      @media only screen and (min-width : 768px) {
        .link {
          @include calc(width, '100% - ' rem-calc(30)); //30 padding
          &::after {
            opacity: 1;
          }
        }
        .content {
          div {
            z-index: 5;
          }
          * {
            color: $white;
          }
        }
      }
    }
    .visual {
      float: left;
      width: rem-calc(110);
      min-height: rem-calc(110);
      height: 100%;
      display: block;
      position: relative;
      z-index: 2;
      background-color: $white;
      background: $white url("../images/agenda1.png") no-repeat center;
      background-size: cover;
      @media only screen and (min-width : 768px) {
        width: rem-calc(200);
        min-height: rem-calc(200);
      }
    }
    .content {
      height: 100%;
      display: flex;
      align-items: center;
      background: $white;
      padding: rem-calc(20);
      @media only screen and (min-width : 768px) {
        padding: rem-calc(30 90 30 30);
      }
      h2,h3,h4,h5,h6 {
        @include transition(all 0.3s ease);
      }
    }
    .link {
      @extend .gbackground;
      float: right;
      height: 100%;
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: rem-calc(15);
      width: rem-calc(75);
      &::after {
        position: absolute;
        content: '';
        height: rem-calc(66);
        width: 100%;
        @include calc(top, '50% - ' rem-calc(33));
        left: 0;
        right: 0;
        opacity: 0.2;
        background: url("../images/icon_chevron.svg") no-repeat calc(100% - 15px) center;
        background-size: contain;
      }
    }
    h4 {
      color: $brand-primary;
      font-size: rem-calc(16);
    }
    h2 {
      font-size: rem-calc(20);
    }
    @media only screen and (min-width : 768px) {
      h4 {
        color: $brand-primary;
        font-size: rem-calc(24);
      }
      h2 {
        font-size: rem-calc(36);
      }
    }
  }
}
