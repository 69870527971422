article {
  &.post {
    header {
      box-shadow: none;
      background: none;
      width: auto;
      position: relative;
    }
  }
}
