.banner {
  min-height: rem-calc(420);
  position: relative;
  background: url("../images/home_banner_bg.jpg") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  @extend .shadow;
  @media only screen and (min-width : 768px) {
    min-height: rem-calc(520);
  }
  &::after {
    position: absolute;
    height: 20px;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: url('../images/border-bottom.png') no-repeat center;
    background-size: cover;
  }
  a, h1 {
    visibility: hidden;
  }
  h1 {
    color: $white;
    text-transform: uppercase;
    margin: rem-calc(35 0);
  }
  img {
    max-width: rem-calc(150);
  }
  .container {
    padding: rem-calc(100 0);
  }
}

.about {
  padding: rem-calc(45 0);
  .h1 {
    margin-bottom: rem-calc(45);
  }
  @include e('single') {
    padding: rem-calc(50 0 30);
    background: url('../images/about_loods.png') no-repeat center;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    min-height: rem-calc(260);
    margin-bottom: rem-calc(20);
    div {
      width: 100%;
    }
    &.s {
      background: url('../images/about_cafe.png') no-repeat center;
      background-size: 100% 100%;
    }
    img {
      max-height: rem-calc(45);
    }
    h2 {
      margin: rem-calc(30 0 50);
      text-transform: uppercase;
      @include transition(all 0.3s ease);
    }
    .link, h2, img {
      color: $white;
      z-index: 1;
      position: relative;
    }
    .link {
      font-family: $headings-font-family;
      text-decoration: underline;
    }
    @media only screen and (min-width : 768px) {
      margin-bottom: 0;
      @include hover-focus {
        @include transform(translate3d(0, rem-calc(-10), 0));
      }
      .link {
        position: absolute;
        bottom: rem-calc(30);
        left: 0;
        right: 0;
        margin: 0 auto;
        @include transition(all 0.3s ease);
      }
      h2 {
        margin: rem-calc(30 0 50);
      }
    }
  }
  .cta {
    h2 {
      margin: rem-calc(0 0 20);
      font-size: rem-calc(35);
      @media only screen and (min-width : 768px) {
        font-size: rem-calc(30);
      }
    }
    small {
      margin-top: rem-calc(50);
      display: block;
    }
    .bottles {
      opacity: 0.2;
      width: 90%;
      display: block;
      position: relative;
      z-index: 0;
      margin: rem-calc(-40) auto;
      @include transition(all 0.3s ease);
      @include hover-focus {
        opacity: 0.4;
      }
      @media only screen and (min-width : 768px) {
        width: 60%;
      }
    }
    a {
      position: relative;
      z-index: 2;
    }
  }
}

.fevents {
  position: relative;
  background: url('../images/events_bg.jpg') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  padding: rem-calc(50 0 205);
  @media only screen and (max-width : 768px) {
    background: $gray-darkest !important;
  }
  @extend .shadow;
  .border {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &::after, &::before {
      position: absolute;
      height: 20px;
      left: 0;
      right: 0;
      content: '';

    }
    &::after {
      bottom: 0;
      background: url('../images/border-bottom.png') no-repeat center;
      background-size: cover;
    }
    &::before {
      background: url('../images/border-top.png') no-repeat center;
      background-size: cover;
    }
  }
  small, h2.h1 {
    color: $white;
  }
  h2.h1 {
    margin: rem-calc(5 0 40);
  }
  .text-center {
    .btn-white {
      margin-top: rem-calc(30);
      display: block;
      @media only screen and (min-width : 526px) {
        display: inline-block;
      }
    }
  }
  @include e('single') {
    border: 3px solid $white;
    width: 100%;
    padding: rem-calc(30 15 15 15);
    margin-bottom: rem-calc(30);
    overflow: hidden;
    display: block;
    background: $white;
    h2 {
      color: $gray-darkest;
      margin-bottom: rem-calc(10);
    }
    .date * {
      color: $brand-primary;
    }
    .btn-purple {
      display: block;
    }
    @media only screen and (min-width : 768px) {
      background: transparent;
      padding: rem-calc(15);
      display: flex;
      h2 {
        color: $white;
        margin: 0;
        padding-right: rem-calc(20);
      }
      .date * {
        color: $white;
      }
      .btn-purple {
        display: inline-block;
      }
      .button {
        display: flex;
        align-items: center;
      }
    }
    @include hover-focus {
      background: $white;
      h2, .date * {
        color: $gray-darkest;
      }
      .date {
        border-color: $gray-darkest;
      }
    }
    * {
      @include transition(all 0.3s ease);
    }
    h2 {
      display: inline-block;
      line-height: rem-calc(55);
    }
    .date {
      min-width: rem-calc(80);
      text-align: center;
      display: block;
      @media only screen and (min-width : 768px) {
        padding: 0 rem-calc(15) 0 0;
        float: left;
        border-right: 2px solid $white;
        margin-right: rem-calc(25);
        display: flex;
        align-items: center;
        >div {
          width: 100%;
        }
      }
      small {
        display: block;
      }
      small, span {
        line-height: 0.9;
      }
    }
  }
}

.logos {
  padding: rem-calc(70 0 60);
  @include e('wrapper') {
    align-items: center;
    display: flex;
  }
  @include e('single') {
    padding: rem-calc(60 0 10);
    margin: 0 auto;
    img {
      max-height: rem-calc(130);
      max-width: 80%;
      filter: grayscale(100%);
      opacity: 0.3;
      @include transition(all 0.3s ease);
      @media only screen and (min-width : 768px) {
        max-width: rem-calc(220);
      }
      &:hover {
        opacity: 1;
        filter: none;
      }
    }
  }
}
.testimonials {
  padding: rem-calc(0 0 140);
  .h1 {
    margin-bottom: rem-calc(70);
  }
  @include e('slideshow') {

    @include e('single') {
      background: $white;
      padding: rem-calc(40 15);
      position: relative;
      overflow: hidden;
      @media only screen and (min-width : 768px) {
        padding: rem-calc(50 40 50 50);
        &::after {
          position: absolute;
          content: '';
          right: rem-calc(-10);
          top: rem-calc(15);
          width: 20%;
          height: 42%;
          background: url('../images/icon_close.svg') no-repeat center;
          background-size: contain;
          opacity: 0.05;
        }
      }
      .img {
        background: #e5e5e5 url("../images/berrie.png") no-repeat center;
        background-size: contain;
        border-radius: 50%;
        border: 2px solid $white;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
      }
      .img, .stars {
        width: rem-calc(150);
        height: rem-calc(150);
        max-width: 100%;
        max-height: 100%;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (min-width : 768px) {
          margin-left: 0;
          margin-right: 0;
        }
      }
      .stars {
        margin-top: rem-calc(35);
        margin-bottom: rem-calc(30);
        height: auto;
        line-height: rem-calc(15);
        @media only screen and (min-width : 768px) {
          margin-top: rem-calc(30);
        }
        i {
          color: $brand-primary;
        }
      }
      b, span {
        color: $gray-darkest;
        line-height: 0.9;
      }
      b {
        display: block;
        @media only screen and (max-width : 768px) {
          margin-top: rem-calc(30);
        }
      }
      span {
        font-size: $font-size-xs;
      }
    }
  }
}
@media only screen and (min-width : 320px) {
}
@media only screen and (min-width : 480px) {
}
@media only screen and (min-width : 768px) {
}
@media only screen and (min-width : 1025px) {
}
@media only screen and (min-width : 1200px) {
}
