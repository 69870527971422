section.arrangement {
  .banner {
    width: 100%;
    min-height: rem-calc(420);
    &::before {
      background: none;
    }
  }
  .container {
    margin-top: rem-calc(-70);
    padding-bottom: rem-calc(100);
  }
  .col-lg-5 {
    margin-top: rem-calc(-70);
  }
  .gblock {
    padding: rem-calc(30);
    @media only screen and (min-width : 768px) {
      padding: rem-calc(50);
    }
    .btn-trans {
      @include hover-focus {
        color: $brand-primary;
      }
    }
    h1 {
      margin-bottom: rem-calc(40);
    }
    .btn {
      margin-top: rem-calc(40);
    }
  }

  .card {
    margin-bottom: 0;
    margin-top: rem-calc(30);
    @media only screen and (min-width : 768px) {
      margin-bottom: rem-calc(30);
      margin-top: 0;
    }
    padding: rem-calc(30);
    padding-bottom: 0;
    border: none;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.2);
    .card-header {
      background-color: $white;
      position: relative;
      padding: 0;
      border: none;
      i {
        @extend .gbackground;
        color: $white;
        font-family: 'Oswald', sans-serif;
        font-size: rem-calc(24);
        font-style: normal;
        height: rem-calc(50);
        line-height: rem-calc(48);
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: -2px;
        width: rem-calc(50);
        display: none;
        @media only screen and (min-width : 768px) {
          display: block;
        }
      }
      h3 {
        margin: 0;
        margin-top: rem-calc(7);
        font-size: rem-calc(24);
        @media only screen and (min-width : 768px) {
          margin-left: rem-calc(80);
        }
        a {
          @include transition(all 0.3s ease);
          display: block;
          width: 100%;
          padding-bottom: rem-calc(40);
          &[aria-expanded=true] {
            &:after {
              @include transition(all 0.3s ease);
              @include transform(rotate(-180deg));
            }
          }

          &:after {
            @include border-radius(40px);
            @include transform(rotate(0deg));
            @include transition(all 0.3s ease);
            background-color: #fff;
            background-image: url('../images/icon_arrow_purple.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 40%;
            content: '';
            height: 40px;
            position: absolute;
            right: 0;
            top: 2px;
            width: 40px;
            z-index: 1;
          }
        }
      }
    }
    .card-block {
      border-top: 1px solid $gray-lightest;
      padding: rem-calc(30 0);
      margin: 0;
      p:first-of-type {
        display: none;
        @media only screen and (min-width : 768px) {
          display: block;
        }
      }
      ul {
        list-style-type: none;
        li {
          position: relative;
          color: $brand-primary;
          margin: rem-calc(10 0);
          &::before {
            position: absolute;
            content: '';
            top: -3px;
            width: rem-calc(30);
            height: 100%;
            background: url("../images/icon_check.svg") no-repeat center;
            background-size: contain;
            left: rem-calc(-40);
          }
        }
      }
    }
  }
}
