.page-header {
  position: relative;
  .btn {
    position: absolute;
    bottom: rem-calc(20);
    right: 0;
    &.btn-white {
      @include hover-focus {
        background: $gray-darkest;
        border-color: $gray-darkest;
        &::after {
        }
      }
    }
  }
  h1 {
    font-size: rem-calc(150);
    opacity: 0.15;
    display: inline-block;
    border-bottom: rem-calc(16) solid $brand-primary;
    @include transform(translate3d(0, rem-calc(66), 0));
    &.hgal {
      position: relative;
      z-index: 5;
    }
  }
  a {
    visibility: hidden;
  }
}

.text-page {
  .featured-slideshow, .acf-map {
    width: 100%;
    min-height: rem-calc(480);
    margin-bottom: rem-calc(30);
    position: relative;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      @media only screen and (min-width : 768px) {
        background: url('../images/kader.png') no-repeat center;
        background-size: 100% 100%;
      }
      z-index: 1;
    }
    .item {
      width: 100%;
      min-height: rem-calc(480);
      background: url('../images/home_banner_bg.jpg') no-repeat center;
      background-size: cover;
    }
  }

  .wblock {
    padding: rem-calc(50);
    position: relative;
    z-index: 2;
    &.hgal {
      margin-top: rem-calc(-110);
    }
    .icon, .h1, h1 {
      margin-bottom: rem-calc(50);
      float: left;
      &.hicon {
        line-height: rem-calc(90);
        margin-left: rem-calc(30);
      }
      img {
        height: rem-calc(45);
      }
    }
    .icon {
      padding: rem-calc(20);
      border: 2px solid #eeeeee;
    }
  }

  .cta {
    padding: rem-calc(50);
    text-align: center;
    margin-top: rem-calc(-30);
    position: relative;
    z-index: 5;
    @extend .gbackground;
    @media only screen and (min-width : 768px) {
      margin-top: 0;
    }
    .btn.btn-trans {
      @include hover-focus {
        border-color: transparent;
      }
    }
    h2 {
      color: $white;
      margin-bottom: rem-calc(40);
    }
  }
}

.gallery {
  margin-top: rem-calc(-50);
  img {
    @media only screen and (min-width : 768px) {
      height: rem-calc(450);
    }
    width: auto;
    border-right: 2px solid $white;
    position: relative;
    z-index: 1;
  }
}
