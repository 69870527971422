footer {
  background: $gray-darkest;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: -20px;
    height: 20px;
    background: url('../images/footer-bt.png') no-repeat center;
    background-size: cover;
  }
  img {
    max-width: rem-calc(130);
    margin-bottom: rem-calc(20);
  }
  h1,h2,h3,h4, .sub-menu {
    display: none;
  }
  ul {
    margin: rem-calc(-12 0 10);
    @extend .list-unstyled;
    * {
      line-height: 3;
    }
  }
  .widget_socialmedia {
    overflow: hidden;
    margin-bottom: rem-calc(20);
  }
  .footer-top .widget_contact {
    margin-bottom: rem-calc(30);
    a {
      color: $white;
    }
  }
  .footer-top a {
    color: $gray-light;
    display: block;
    &.current-menu-item {
      color: $white;
    }
    &.btn {
      display: none;
    }
    @include hover-focus {
      color: $white;
    }
  }
  b, p, span, * {
    color: $white;
    line-height: 1.5;
  }
  i {
    color: $gray-darkest;
  }
  .footer-top {
    padding: rem-calc(60 0 50);
    &.archive {
      padding-top: rem-calc(180);
    }
  }
  .footer-bottom {
    position: relative;
    background: #191919;
    padding: rem-calc(20 0);
    @media only screen and (min-width : 768px) {
      padding: 0;
    }
    a, * {
      color: #9e9e9e;
      @media only screen and (min-width : 768px) {
        line-height: rem-calc(66);
      }
    }
    &::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: -20px;
      height: 20px;
      background: #191919 url('../images/footer-bb.png') no-repeat center;
      background-size: cover;
    }
  }
}

.btn-social {
  width: rem-calc(42);
  height: rem-calc(42);
  background: $gray;
  border-radius: 50%;
  margin-right: rem-calc(12);
  margin-bottom: rem-calc(12);
  float: left;
  display: inline-block;
  line-height: rem-calc(48);
  text-align: center;
  font-size: $font-size-lg;
  color: $gray-darkest;
  @include hover-focus {
    background: $white;
  }
}

@media only screen and (min-width : 320px) {
}
@media only screen and (min-width : 480px) {
}
@media only screen and (min-width : 768px) {
}
@media only screen and (min-width : 1025px) {
}
@media only screen and (min-width : 1200px) {
}
